* {
  box-sizing: border-box;
}

:root {
  --primary-text: #2b1643;
  --primary-color: #331b4f;
}

body {
  margin: 0;
  min-width: 100vw;
  min-height: 100vh;

  background-image: url(../public/assets/background.jpg);
  background-size: cover;
  background-position: bottom left;

  color: var(--primary-text);

  font-family: "Work Sans", Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
