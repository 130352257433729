.Upload {
	width: 100vw;
	height: 100vh;
}

.Upload > * {
	margin-top: 70px;
}

.Upload .drop-target {
	width: 40vw;
	height: 40vh;
	perspective: 1000px;
	transform-origin: bottom center;
}

.Upload .drop-target:hover {
	cursor: pointer;
}

.Upload .img {
	position: absolute;
	width: 35vh;
	height: 35vh;

	/* border: solid white 15px; */
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	transform-origin: bottom center;
	transition: transform 0.4s ease-out, opacity 0.6s;
	pointer-events: none;
}

.Upload .img.frame {
	width: 40vh;
	height: 40vh;
}

.Upload .img.scanner {
	background-color: white;
	height: 4vh;
	mix-blend-mode: difference;
    animation: scanning 2s ease-in-out;
    animation-iteration-count: infinite;	
}

.Upload .shadow {
	position: absolute;
	width: 45vh;
	height: 100px;
	bottom: -65px;
	transition: transform 0.4s ease-out;
	background-size: 100% 100%;
}

@keyframes scanning {
	0%,
	100% {
		transform: translateY(16vh) scaleY(0.2);
	}
	50% {
		transform: translateY(-16vh);
	}
}

.Overlay.above-picture {
	margin-top: 10vh;
	width: 100vw;
}
