.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.grow {
  flex-grow: 1;
}

.wrap {
  flex-wrap: wrap;
}

.Overlay {
  position: absolute;
}
.Overlay.top {
  top: 0;
}
.Overlay.bottom {
  bottom: 0;
}
.Overlay.left {
  left: 0;
}
.Overlay.right {
  right: 0;
}

h1 {
  font-size: 48px;
}

button {
  color: var(--primary-text);
  border-style: solid;
  border-color: var(--primary-color);
  background: transparent;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
  background: rgba(227, 227, 227, 0.15);
}

button.large {
  padding: 11px 20px;
  border-width: 1px;
  font-size: 24px;
}

button.Logout {
  margin: 20px 16px 0 0;
  border-radius: 50%;
  border: none;
}

button.Logout img {
  width: 30px;
  border: none;
}

input.hidden {
  width: 1px;
  height: 1px;
  opacity: 0;
}

.preview-image {
  background-size: cover;
}

.smallprint {
  font-size: 14px;
  text-align: center;
}

.Bubble {
  padding: 8px 16px;
  border-style: solid;
  border-width: 1px;
  border-radius: 50px;
  border-color: var(--primary-text);
  background: rgba(0, 0, 0, 0.15);
  color: var(--primary-text);
  transition: opacity 0.8s;
}
.Bubble.err {
  border-color: #ee4242;
  background: rgba(116, 6, 6, 0.15);
  color: #ee4242;
}
.Bubble.warn {
  border-color: #f7a520;
  background: rgba(120, 90, 13, 0.15);
  color: #f7a520;
}
.Bubble.confirm {
  border-color: #31CC18;
  background: rgba(21, 116, 6, 0.15);
  color: #31CC18;
}
.Bubble.hidden {
  opacity: 0;
}
