.PicturePop .drop-target {
	width: 40vw;
	height: 40vh;
	perspective: 1000px;
	transform-origin: bottom center;
}

.PicturePop .drop-target:hover {
	cursor: pointer;
}

.PicturePop .img {
	position: absolute;
	width: 35vh;
	height: 35vh;

	/* border: solid white 15px; */
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	transform-origin: bottom center;
	transition: transform 0.4s ease-out, opacity 0.6s;
	pointer-events: none;
}

.PicturePop .img.frame {
	width: 40vh;
	height: 40vh;
}

.PicturePop .img.cover {
	background-color: white;
	width: 40vh;
	height: 2.5vh;
	margin-top: 37.5vh;
	backface-visibility: hidden;
	text-align: center;
	line-height: 2.5vh;
	font-size: 11px;
	border-radius: 0.3vh 0.3vh 0 0;
}

.PicturePop .img.scanner {
	background-color: white;
	height: 4vh;
	mix-blend-mode: difference;
	animation: scanning 2s ease-in-out;
	animation-iteration-count: infinite;
	transition: rotate 0.4s ease-out;	
}

.PicturePop .shadow {
	position: absolute;
	width: 45vh;
	height: 100px;
	bottom: -65px;
	transition: transform 0.4s ease-out;
	background-size: 100% 100%;
}



@keyframes scanning {
	0%,
	100% {
		transform: translateY(16vh) scaleY(0.2);
	}
	50% {
		transform: translateY(-16vh);
	}
}
